body {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 80s linear;
  }
}

.App-header {
  background-color: #282c34;
  height: 4vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 0px;
  /* padding-bottom: 20px; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.plate {
  width: 480px;
  border: 0;
  border-radius: 7px;
  padding-right: 14px;
  padding-bottom: 14px;
  background-color: rgba(255,255,255,0.2);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.well {
  border: 1px solid white;
  font-size: 0.7em;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  height: auto;
  border-radius: 20%;
  background-color: #282c34;
}

.row .spot {
  border: 1px solid rgba(0, 0, 0, 0);
  font-size: 0.7em;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  height: auto;

  background-color: inherit;
}

.header.row .spot {
  border-radius: 0;
}

.well:before {
  /* The psuedo-element's padding-top percentage is based on the element's width. */
  padding-top: 100%;
  content: '';
  float: left;
}

/* .well.selected {
  background: repeating-linear-gradient(
    -30deg,
    #606dbc,
    #606dbc 5px,
    #465298 5px,
    #465298 10px
  );
} */

.well:hover {
  background-color: #4066b1;
  cursor: pointer;
}

.spot:hover {
  cursor: pointer;
  background-color: rgba(255,255,255,0.3);
}

/* .well.selected:hover {
  background: repeating-linear-gradient(
    -30deg,
    #7d8ade,
    #7d8ade 5px,
    #404a82 5px,
    #404a82 10px
  );
} */

.movementPattern {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 550px;
}

.App-body {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: center;
  color: white;
  padding-bottom: 20px;
  padding-top: 25px;
  width: 100vw;
  height: 830px;
  padding-left: 7px;
  padding-right: 7px;
}

.PlateDefinition {
  width: 550px;
}

.plateMapTemplate {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}

.repeatDefinition {
  width: 150px;
  display: flex;
  margin-top: 14px;
}

.repeatDefinition .labels, .repeatDefinition .fields {
  display: flex;
  flex-direction: column;
}

.repeatDefinition > div:last-child {
  flex: 1;
}

.repeatDefinition label, .repeatDefinition input {
  height: 25px;
  padding: 5px;
  box-sizing: border-box;
  text-align: left;
}

.repeatDefinition input[type='number'] {
  width: 4em;
}

.plateSet {
  overflow-y: auto;
  max-height: 725px;
}