@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
a,
button {
  all: unset;
}

.ToolbarRoot {
  display: flex;
  padding: 10px;
  width: 79px;
  height: 300px;
  min-height: max-content;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
}

.ToolbarToggleItem,
.ToolbarLink,
.ToolbarButton {
  flex: 0 0 auto;
  color: var(--mauve-11);
  height: 25px;
  padding: 0 5px;
  border-radius: 4px;
  display: inline-flex;
  font-size: 13px;
  line-height: 1;
  align-items: center;
  justify-content: center;
}
.ToolbarToggleItem:hover,
.ToolbarLink:hover,
.ToolbarButton:hover {
  background-color: var(--violet-3);
  color: var(--violet-11);
}
.ToolbarToggleItem:focus,
.ToolbarLink:focus,
.ToolbarButton:focus {
  position: relative;
  box-shadow: 0 0 0 2px var(--violet-7);
}

.ToolbarToggleItem {
  background-color: white;
  margin-left: 2px;
}
.ToolbarToggleItem:first-child {
  margin-left: 0;
}
.ToolbarToggleItem[data-state='on'] {
  background-color: var(--violet-5);
  color: var(--violet-11);
}

.ToolbarSeparator {
  width: 1px;
  background-color: var(--mauve-6);
  margin: 0 10px;
}

.ToolbarLink {
  background-color: transparent;
  color: var(--mauve-11);
  display: none;
  justify-content: center;
  align-items: center;
}
.ToolbarLink:hover {
  background-color: transparent;
  cursor: pointer;
}
@media (min-width: 520px) {
  .ToolbarLink {
    display: inline-flex;
  }
}

.ToolbarButton {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  background-color: var(--violet-9);
}
.ToolbarButton:hover {
  background-color: var(--violet-10);
  color: white;
}